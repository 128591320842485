import React from 'react'
import styles from './not-found.module.scss'
const index = () => {
  return (
    <div className={styles.notFound}>
      <h2>We can't find what you're looking for...</h2>

      <div className={styles.secondPartContainer}>
        <div className={styles.secondPart}>
          <p>ERROR</p>
          <p>404</p>
        </div>
      </div>
    </div>
  );
}

export default index